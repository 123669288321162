import { Sidetab } from "@typeform/embed-react";
import { PropsWithChildren, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { pathSegmentOfEnum } from "shared/config/base-urls";
import { EnabledPage } from "shared/model/pages";

import { useTenantId } from "../util/use-active-tenant-id";

const TYPEFORM_CONFIG_VARIABLES = [
  "formId",
  "buttonText",
  "buttonColor",
] as const;

export const InjectTypeForm: React.FC<
  PropsWithChildren<{ page?: EnabledPage }>
> = ({ children, page }) => {
  const {
    t,
    i18n: { exists },
  } = useTranslation();
  const { disease } = useTenantId();
  const pathOfDisease = pathSegmentOfEnum(disease);

  const getTypeFormConfigItem = useCallback(
    (item: (typeof TYPEFORM_CONFIG_VARIABLES)[number]) =>
      `disease.${pathOfDisease}.${page?.txPageName}.typeFormConfig.${item}`,
    [page?.txPageName, pathOfDisease],
  );

  const isFormInCurrentLocale = page?.txPageName
    ? TYPEFORM_CONFIG_VARIABLES.every((item) =>
        exists(getTypeFormConfigItem(item), { fallbackLng: "false" }),
      )
    : false;

  return (
    <>
      {isFormInCurrentLocale && (
        <Sidetab
          id={t(getTypeFormConfigItem("formId"))}
          buttonText={t(getTypeFormConfigItem("buttonText"))}
          buttonColor={t(getTypeFormConfigItem("buttonColor"))}
        />
      )}
      {children}
    </>
  );
};
