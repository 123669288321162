import { useState } from "react";

import { BasicWithButton } from "./fragments/basic-with-button";
import { TextInput as TextInputInternal } from "../../../../../form/text-input";
import { ChatOptionComponentProps } from "../lookup";

export const TextInput: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const [text, setText] = useState("");

  return (
    <BasicWithButton
      sendResponse={(props) => {
        sendResponse(props);
        setText("");
      }}
      message={text}
    >
      <TextInputInternal
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
    </BasicWithButton>
  );
};
