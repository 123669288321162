import { useState } from "react";

import { BasicWithButton } from "./fragments/basic-with-button";
import { TextInput } from "../../../../../form/text-input";
import { ChatOptionComponentProps } from "../lookup";

export const EmailInput: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const [email, setEmail] = useState("");

  return (
    <BasicWithButton sendResponse={sendResponse} message={email}>
      <TextInput
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        placeholder={{ tx: "auth.signUp.inputs.email.placeholder" }}
      />
    </BasicWithButton>
  );
};
