import { twMerge } from "tailwind-merge";

import { LoadingSpinner } from "./loading-spinner";
import { PlainI18nProps } from "./text";

export const LoadingScreen: React.FC<{
  message?: PlainI18nProps;
  className?: string;
}> = ({ message = { tx: "general.loading" }, className }) => {
  return (
    <div
      className={twMerge(
        "flex h-full w-full items-center justify-center",
        className,
      )}
    >
      <LoadingSpinner className={className} message={message} />
    </div>
  );
};
