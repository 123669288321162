import {
  DeploymentEnvironment,
  subdomainOfDeploymentEnv,
} from "shared/config/deployment-environments";

const missingEnv = (envName: string): never => {
  throw Error(`Missing env variable ${envName}`);
};

const incorrectEnv = (envName: string): never => {
  throw Error(`Incorrect env variable ${envName}: ${process.env[envName]}`);
};

export const appEnv = (process.env.REACT_APP_APP_ENV ??
  missingEnv("REACT_APP_APP_ENV")) as DeploymentEnvironment;

export const isNotLocalhost = process.env.REACT_APP_IS_LOCALHOST !== "1";

export const sentryRelease = process.env.REACT_APP_SENTRY_RELEASE;

export const subdomainOfAppEnv =
  subdomainOfDeploymentEnv[appEnv] ?? incorrectEnv("REACT_APP_APP_ENV");

const parseUrlFromEnv = (envName: string): string =>
  (process.env[envName] ?? missingEnv(envName))
    .replace(/{REACT_APP_APP_ENV}/g, subdomainOfAppEnv)
    .replace(/{ORIGIN}/g, location.origin);

export const backendApiUrl = parseUrlFromEnv("REACT_APP_BACKEND_API_URL");

export const multiagentApiUrl = parseUrlFromEnv("REACT_APP_MULTIAGENT_API_URL");

export const landbotApiUrl = parseUrlFromEnv("REACT_APP_LANDBOT_API_URL");

export const adminDashboardUrl = parseUrlFromEnv(
  "REACT_APP_ADMIN_DASHBOARD_URL",
);
