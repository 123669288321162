import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

import { tx } from "../../../../../text";
import { ValidationFunction } from "../lookup";

export const phoneValidation: ValidationFunction = (value) => {
  if (
    !isPossiblePhoneNumber(String(value)) ||
    !isValidPhoneNumber(String(value))
  ) {
    return { ok: false, message: { tx: tx("profile.notAValidPhoneNumber") } };
  }

  return {
    ok: true,
  };
};
