import { ChatType, FrontendPageEnum } from "shared/model/pages";

import { AiChat } from "../components/chat/ai/ask/ai-chat";
import { LandbotChat } from "../components/chat/landbot/landbot-chat";
import { usePageSettings } from "../tenant-settings";

export const Chat: React.FC = () => {
  const chatSettings = usePageSettings(FrontendPageEnum.CHAT);

  if (!chatSettings?.chatType) {
    return null;
  }
  switch (chatSettings.chatType) {
    case ChatType.LANDBOT:
      return <LandbotChat />;
    case ChatType.AI:
      return <AiChat />;
  }
};
