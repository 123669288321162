"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AUTH_ROUTE = exports.AnalyticsAppPagesEnum = void 0;
var AnalyticsAppPagesEnum;
(function (AnalyticsAppPagesEnum) {
    AnalyticsAppPagesEnum["OVERVIEW"] = "/overview";
    AnalyticsAppPagesEnum["SYMPTOMS_EXPLORER"] = "/symptmos-explorer";
    AnalyticsAppPagesEnum["CARE_PATHWAY"] = "/care-pathway";
    AnalyticsAppPagesEnum["TREATMENT_PATHWAY"] = "/treatment-pathway";
    AnalyticsAppPagesEnum["MIND_AND_BEHAVIOUR"] = "/mind-and-behaviour";
    AnalyticsAppPagesEnum["HELP"] = "/help";
})(AnalyticsAppPagesEnum || (exports.AnalyticsAppPagesEnum = AnalyticsAppPagesEnum = {}));
exports.AUTH_ROUTE = "/auth/sign-in";
