import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { TextInput, TextInputProps } from "./text-input";

export const AutoResizingTextInput: React.FC<Omit<TextInputProps, "rows">> = ({
  value,
  onChange,
  ...rest
}) => {
  const [rows, setRows] = useState(1);

  const onChatInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setRows(e.target.value.split("\n").length);
      onChange?.(e);
    },
    [onChange],
  );

  useEffect(() => {
    if (value === "" || value === undefined) {
      setRows(1);
    }
  }, [value]);

  return (
    <TextInput
      rows={rows}
      value={value}
      onChange={onChatInputChange}
      {...rest}
    />
  );
};
