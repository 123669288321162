import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { DeploymentEnvironment } from "shared/config/deployment-environments";
import { Disease } from "shared/model/diseases";
import {
  ConversationWithMessages,
  WebSocketResponseEventData,
} from "shared/model/websocket/schema";
import { CognitoClientMetadata } from "shared/types/cognito-client-metadata";

import { Conversation } from "./conversation";
import { mamaAskChatDrawerComponents } from "./drawer/lookup";
import { PublicConversationWithMessages } from "../../../../api/generated/multiagent";
import { usePublicApiQuery } from "../../../../api/use-public-api";
import { usePublicWebSocket } from "../../../../api/use-public-web-socket";
import { useCognitoConfiguration } from "../../../../auth/implementations/cognito/hooks/use-cognito-configuration";
import {
  signUserIn,
  signUserUp,
} from "../../../../auth/implementations/cognito/misc/cognito-magic-link-methods";
import { cognitoAuthRoutesWithReducedLayout } from "../../../../auth/implementations/cognito/routes";
import { AuthWrapper } from "../../../../auth/implementations/cognito/routes/cognito-layout";
import {
  SignInStatus,
  SignUpStatus,
} from "../../../../auth/implementations/cognito/routes/cognito-magic-link-sign-up";
import {
  PUBLIC_CHAT_CONVERSATION_KEY,
  START_PUBLIC_CONVERSATION_KEY,
} from "../../../../types/query-keys";
import { userHasStartedSignup } from "../../../../util/data-layer-actions";
import { appEnv, isNotLocalhost } from "../../../../util/env-utils";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { useGetExtendedTheme } from "../../../../util/use-get-theme";
import { useQueryParamsForUserSignUp } from "../../../../util/use-save-query-params-to-local-storage";
import { LoadingScreen } from "../../../loading-screen";

export const PUBLIC_CHAT_LOCAL_STORAGE_KEY = "public-chat-credentials";

export type PublicChatCredentials = {
  userId: string;
  token: string;
  conversationId: string;
  language: string;
  disease: string;
};

type MagicLinkClientMetadata = CognitoClientMetadata & {
  userId: string;
  authFlowType: string;
  signInMethod: string;
  alreadyHaveMagicLink: string;
} & { [key: string]: string };

export const PublicChat: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const { colors } = useGetExtendedTheme();
  const { disease, organisation } = useTenantId();
  const [query] = useSearchParams();
  const entrypoint = useMemo(() => {
    if (disease === Disease.ENDOMETRIOSIS && language === "de-DE")
      return "entrypoint_endostudy";

    return query.get("entrypoint") ?? "entrypoint_default";
  }, [disease, language, query]);
  const configurationState = useCognitoConfiguration();
  const publicConversationUserAttributes = useQueryParamsForUserSignUp();

  const [startNewConversation, setStartNewConversation] = useState(false);

  const [conversation, setConversation] = useState<
    PublicConversationWithMessages | undefined
  >(undefined);

  const [publicChatCredentials, setPublicChatCredentials] =
    useState<PublicChatCredentials>();

  useEffect(() => {
    const creds = localStorage.getItem(PUBLIC_CHAT_LOCAL_STORAGE_KEY);
    if (!creds) {
      setStartNewConversation(true);
      return;
    }

    const parsedCreds = JSON.parse(creds);
    if (parsedCreds.disease !== disease || parsedCreds.language !== language) {
      setStartNewConversation(true);
      return;
    }

    setPublicChatCredentials(creds ? JSON.parse(creds) : undefined);
  }, [disease, language]);

  usePublicApiQuery(
    "multiagent",
    (api) =>
      api.startPublicConversation({
        mamaDisease: disease,
        mamaLanguage: language,
        entrypoint: entrypoint ?? undefined,
        createPublicConversationUserAttributes:
          publicConversationUserAttributes,
      }),
    [START_PUBLIC_CONVERSATION_KEY(disease)],
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ conversationId, token, userId }) => {
        const creds = {
          conversationId,
          token,
          userId,
          language,
          disease,
        };
        localStorage.setItem(
          PUBLIC_CHAT_LOCAL_STORAGE_KEY,
          JSON.stringify(creds),
        );
        setPublicChatCredentials(creds);
        userHasStartedSignup();
      },
      enabled:
        startNewConversation &&
        !!entrypoint &&
        !!publicConversationUserAttributes,
    },
  );

  usePublicApiQuery(
    "multiagent",
    (api) =>
      api.getPublicConversationWithId({
        mamaUser: publicChatCredentials?.userId as string,
        authorization: publicChatCredentials?.token as string,
        mamaConversationId: publicChatCredentials?.conversationId as string,
      }),
    [PUBLIC_CHAT_CONVERSATION_KEY(disease)],
    {
      enabled: !!publicChatCredentials,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setConversation(data);
      },
    },
  );

  const useAgnosticWebSocket = useCallback(
    (params: {
      query: {
        language: string;
        disease: string;
        conversation_id: string;
      };
      onResponse: (
        event?: MessageEvent<WebSocketResponseEventData>,
      ) => Promise<void> | void;
    }) => {
      return usePublicWebSocket({
        url: getWebSocketUrl(),
        query: {
          ...params.query,
          temp_token: publicChatCredentials?.token as string,
          external_user_id: publicChatCredentials?.userId as string,
        },
        onResponse: params.onResponse,
      });
    },
    [publicChatCredentials?.token, publicChatCredentials?.userId],
  );

  const clientMetadataMemo: MagicLinkClientMetadata | undefined = useMemo(
    () =>
      publicChatCredentials?.userId
        ? {
            disease,
            ...(organisation ? { organisation } : {}),
            isLocalhost: process.env.NODE_ENV === "development" ? "1" : "0",
            authFlowType: "CUSTOM_WITHOUT_SRP",
            signInMethod: "MAGIC_LINK",
            alreadyHaveMagicLink: "no",
            userId: publicChatCredentials.userId,
            token: publicChatCredentials.token,
            language,
          }
        : undefined,
    [
      publicChatCredentials?.userId,
      publicChatCredentials?.token,
      disease,
      organisation,
      language,
    ],
  );

  const onSubmitTrySignUp = useCallback(
    ({ email, name }: { email: string; name: string }) => {
      if (!clientMetadataMemo) {
        alert("Client metadata is not set");
        return;
      }

      return signUserIn({
        email,
        name,
        clientMetadata: clientMetadataMemo,
      }).then((result) => {
        switch (result.status) {
          case SignInStatus.MagicLinkSent:
            console.log("Magic link sent");
            break;
          case SignInStatus.UserNotFound:
            return signUserUp({
              email,
              name,
              clientMetadata: clientMetadataMemo,
            }).then((signUpResult) => {
              switch (signUpResult.status) {
                case SignUpStatus.MagicLinkSent:
                  console.log("Magic link sent after sign up");
                  break;
                case SignUpStatus.SignUpNotDone:
                  Sentry.captureException(signUpResult, {
                    user: { email: email },
                    level: "error",
                  });
                  alert("Sign up could not be finished: " + signUpResult.error);
                  break;
                case SignUpStatus.UnknownError:
                  Sentry.captureException(signUpResult, {
                    user: { email: email },
                    level: "error",
                  });
                  alert("Unknown sign up error: " + signUpResult.error);
                  break;
              }
            });
          case SignInStatus.UnknownError:
            Sentry.captureException(result, {
              user: { email: email },
              level: "error",
            });
            alert("Unknown sign in error: " + result.error);
            return;
        }
      });
    },
    [clientMetadataMemo],
  );

  return !conversation || configurationState !== "ready" ? (
    <LoadingScreen message={{ tx: "chat.loadingInitialChat" }} />
  ) : (
    <AuthWrapper routes={cognitoAuthRoutesWithReducedLayout}>
      <Conversation
        chatDrawerComponents={mamaAskChatDrawerComponents}
        useAgnosticWebSocket={useAgnosticWebSocket}
        conversation={conversation as ConversationWithMessages}
        shouldShowChatCompletion={false}
        triggerSignUp={onSubmitTrySignUp}
        userChatBubbleColor={colors?.mamaAskUserChatBubble}
      />
    </AuthWrapper>
  );
};

const getWebSocketUrl = () => {
  return !isNotLocalhost
    ? "ws://localhost:8000/public/ws"
    : appEnv === DeploymentEnvironment.DEV
    ? "wss://ws.dev.mamahealth.com/public/ws"
    : "wss://ws.app.mamahealth.com/public/ws";
};
