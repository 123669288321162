import { useCallback } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
import { basePathOfTenant } from "shared/config/base-urls";
import { FrontendPageEnum } from "shared/model/pages";

import { useTenantId } from "./use-active-tenant-id";

export const useNavigateInsideTenant = (): ((
  page: FrontendPageEnum | string,
  options?: NavigateOptions,
) => void) => {
  const navigate = useNavigate();
  const activeTenant = useTenantId();
  return useCallback(
    (page, options) => navigate(basePathOfTenant(activeTenant) + page, options),
    [activeTenant, navigate],
  );
};

export const useNavigateBack = (): (() => void) => {
  const navigate = useNavigate();
  return useCallback(() => navigate(-1), [navigate]);
};
