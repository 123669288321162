import { isOpadeIdRegex } from "../../../../../../util/regex";
import { ValidationFunction } from "../lookup";

export const opadeIdValidation: ValidationFunction = (value) => {
  if (!isOpadeIdRegex.test(String(value))) {
    return {
      ok: false,
      message: {
        tx: "signUp.customFields.opadePatientId.errorOpadeIdFormat",
      },
    };
  }

  return {
    ok: true,
  };
};
