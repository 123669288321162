import { useState } from "react";
import { twMerge } from "tailwind-merge";

import { LoadingScreen } from "./loading-screen";

export interface IframeProps {
  url: string;
  className?: string;
  allowFullScreen?: boolean;
}

export const Iframe: React.FC<IframeProps> = ({
  url,
  className,
  allowFullScreen,
}) => {
  const [loading, setLoading] = useState(true);
  return (
    <>
      <LoadingScreen className={`${!loading ? "hidden" : ""}`} />
      <iframe
        className={twMerge(
          `h-full w-full ${loading ? "hidden" : ""}`,
          className,
        )}
        src={url}
        onLoad={() => {
          setLoading(false);
        }}
        allowFullScreen={allowFullScreen}
      />
    </>
  );
};
