import assert from "assert";
import { Amplify } from "aws-amplify";
import { useEffect, useState } from "react";
import { cognitoInfoApp } from "shared/config/cognito";
import { DeploymentEnvironment } from "shared/config/deployment-environments";

import { useTenantId } from "../../../../util/use-active-tenant-id";
import { CognitoConfigurationState } from "../misc/types";

const env = process.env.REACT_APP_APP_ENV;
assert(
  env &&
    Object.values(DeploymentEnvironment).includes(env as DeploymentEnvironment),
  "env variable REACT_APP_APP_ENV is missing or not in REACT_APP_APP_ENV",
);
const getCognitoInfo = cognitoInfoApp[env as DeploymentEnvironment];

export const useCognitoConfiguration = (): CognitoConfigurationState => {
  const [state, setState] = useState<CognitoConfigurationState>("loading");
  const tenantId = useTenantId();

  useEffect(() => {
    Amplify.configure({
      Auth: {
        Cognito: getCognitoInfo(
          tenantId,
          process.env.NODE_ENV === "development",
        ),
      },
    });
    setState("ready");
  }, [tenantId]);

  return state;
};
