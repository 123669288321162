import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { organisationMap } from "shared/config";
import { basePathOfTenant } from "shared/config/base-urls";
import { Organisation } from "shared/model/organisations";

import { frontendPages } from "./frontend-pages";
import { isIFramePage } from "./is-page-type";
import { useGetPageInfo } from "./use-get-page-info";
import { useTenantPages } from "./use-tenant-pages";
import {
  ChildNavbarRoute,
  NavbarRoute,
  ParentNavbarRoute,
} from "../components/header";
import { useT } from "../i18n/use-t";

export const useHeaderRoutes = (): NavbarRoute[] | undefined => {
  const tenantPages = useTenantPages();
  const getPageInfo = useGetPageInfo();

  const { pathname } = useLocation();

  const flatRoutes = useMemo(
    () =>
      tenantPages
        ?.filter(
          (page) =>
            isIFramePage(page.page) ||
            !frontendPages[page.page.route].hideInNavbar,
        )
        .map(
          ({
            page,
            tenantId: tenantId,
          }): ChildNavbarRoute & {
            organisation?: Organisation;
            groupedByTitle?: string;
          } => ({
            page,
            path: basePathOfTenant(tenantId) + page.route,
            title: getPageInfo({ page, tenantId }).title,
            groupedByTitle: getPageInfo({ page, tenantId }).groupedByTitle,
            organisation: tenantId.organisation,
            isActive: !!matchPath(
              pathname,
              basePathOfTenant(tenantId) + page.route,
            ),
            position: tenantId.organisation ? "end" : "start",
          }),
        ),
    [tenantPages, getPageInfo, pathname],
  );

  return useHierachicalRoutes(flatRoutes);
};

const useHierachicalRoutes = (
  flatRoutes?: (ChildNavbarRoute & {
    organisation?: Organisation;
    groupedByTitle?: string;
  })[],
): NavbarRoute[] => {
  const t = useT();
  return useMemo(() => {
    if (!flatRoutes) {
      return [];
    }
    const routesGroupedByTitle = new Map<string, ChildNavbarRoute[]>();

    for (const route of flatRoutes) {
      if (route.groupedByTitle) {
        const menuRoutes = routesGroupedByTitle.get(route.groupedByTitle) ?? [];
        menuRoutes.push(route);
        routesGroupedByTitle.set(route.groupedByTitle, menuRoutes);
      }
    }

    const routesGroupedByOrganisation = new Map<
      Organisation | undefined,
      ChildNavbarRoute[]
    >();

    for (const route of flatRoutes) {
      if (!route.groupedByTitle) {
        const organisationRoutes =
          routesGroupedByOrganisation.get(route.organisation) ?? [];
        organisationRoutes.push(route);
        routesGroupedByOrganisation.set(route.organisation, organisationRoutes);
      }
    }

    return [
      ...(routesGroupedByOrganisation.get(undefined) ?? []),
      ...[...routesGroupedByTitle.entries()].map(
        ([title, routes]): ParentNavbarRoute => ({
          title,
          childRoutes: routes,
          uniqueKey: title,
          position: "start",
        }),
      ),
      ...[...routesGroupedByOrganisation.entries()]
        .filter(([organisation]) => organisation)
        .map(
          ([organisation, routes]): ParentNavbarRoute => ({
            title: t({
              tx: organisationMap[organisation as Organisation].tenantNameTx,
            }),
            childRoutes: routes,
            uniqueKey: organisation as string,
            position: "end",
          }),
        ),
    ];
  }, [flatRoutes, t]);
};
