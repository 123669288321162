/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MessageWithType } from './MessageWithType';
import {
    MessageWithTypeFromJSON,
    MessageWithTypeFromJSONTyped,
    MessageWithTypeToJSON,
} from './MessageWithType';

/**
 * 
 * @export
 * @interface ConversationWithMessages
 */
export interface ConversationWithMessages {
    /**
     * 
     * @type {string}
     * @memberof ConversationWithMessages
     */
    id: string;
    /**
     * 
     * @type {Array<MessageWithType>}
     * @memberof ConversationWithMessages
     */
    messages: Array<MessageWithType>;
    /**
     * 
     * @type {string}
     * @memberof ConversationWithMessages
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof ConversationWithMessages
     */
    inputTime: Date;
    /**
     * 
     * @type {string}
     * @memberof ConversationWithMessages
     */
    type?: string;
}

/**
 * Check if a given object implements the ConversationWithMessages interface.
 */
export function instanceOfConversationWithMessages(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "messages" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "inputTime" in value;

    return isInstance;
}

export function ConversationWithMessagesFromJSON(json: any): ConversationWithMessages {
    return ConversationWithMessagesFromJSONTyped(json, false);
}

export function ConversationWithMessagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConversationWithMessages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'messages': ((json['messages'] as Array<any>).map(MessageWithTypeFromJSON)),
        'userId': json['userId'],
        'inputTime': (new Date(json['inputTime'])),
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function ConversationWithMessagesToJSON(value?: ConversationWithMessages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'messages': ((value.messages as Array<any>).map(MessageWithTypeToJSON)),
        'userId': value.userId,
        'inputTime': (value.inputTime.toISOString()),
        'type': value.type,
    };
}

