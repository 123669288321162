"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allGroupsRequiringPassword = exports.nonUserGroups = exports.allSupportedGroups = exports.NonUserGroupsEnum = exports.SupportedGroupsEnum = exports.allIdps = exports.IdpEnum = void 0;
const utility_1 = require("../utils/utility");
var IdpEnum;
(function (IdpEnum) {
    IdpEnum["FACEBOOK"] = "facebook";
})(IdpEnum || (exports.IdpEnum = IdpEnum = {}));
exports.allIdps = (0, utility_1.allEnumValues)({
    [IdpEnum.FACEBOOK]: IdpEnum.FACEBOOK,
});
// duplicated in Prisma schema as UserGroup
// !note: all values NEED to be capitalised or else the post confirmation lambda will not work (it capitalises group names since they are stored lowercase in the AWS secrets manager)
var SupportedGroupsEnum;
(function (SupportedGroupsEnum) {
    SupportedGroupsEnum["USER"] = "USER";
    SupportedGroupsEnum["ADMIN"] = "ADMIN";
    SupportedGroupsEnum["MEMBER"] = "MEMBER";
    SupportedGroupsEnum["PAG"] = "PAG";
    SupportedGroupsEnum["PHARMA"] = "PHARMA";
    SupportedGroupsEnum["PHARMA_DEMO"] = "PHARMA_DEMO";
})(SupportedGroupsEnum || (exports.SupportedGroupsEnum = SupportedGroupsEnum = {}));
var NonUserGroupsEnum;
(function (NonUserGroupsEnum) {
    NonUserGroupsEnum["PAG"] = "PAG";
    NonUserGroupsEnum["PHARMA"] = "PHARMA";
    NonUserGroupsEnum["PHARMA_DEMO"] = "PHARMA_DEMO";
})(NonUserGroupsEnum || (exports.NonUserGroupsEnum = NonUserGroupsEnum = {}));
exports.allSupportedGroups = (0, utility_1.allEnumValues)({
    [SupportedGroupsEnum.USER]: SupportedGroupsEnum.USER,
    [SupportedGroupsEnum.ADMIN]: SupportedGroupsEnum.ADMIN,
    [SupportedGroupsEnum.MEMBER]: SupportedGroupsEnum.MEMBER,
    [SupportedGroupsEnum.PAG]: SupportedGroupsEnum.PAG,
    [SupportedGroupsEnum.PHARMA]: SupportedGroupsEnum.PHARMA,
    [SupportedGroupsEnum.PHARMA_DEMO]: SupportedGroupsEnum.PHARMA_DEMO,
});
exports.nonUserGroups = (0, utility_1.allEnumValues)({
    [NonUserGroupsEnum.PAG]: NonUserGroupsEnum.PAG,
    [NonUserGroupsEnum.PHARMA]: NonUserGroupsEnum.PHARMA,
    [NonUserGroupsEnum.PHARMA_DEMO]: NonUserGroupsEnum.PHARMA_DEMO,
});
exports.allGroupsRequiringPassword = exports.allSupportedGroups.filter((group) => group === SupportedGroupsEnum.ADMIN);
