import { confirmSignIn, signIn, signUp } from "aws-amplify/auth";
import { StringMap } from "shared/types/cognito-client-metadata";

import { isCognitoError } from "./cognito-errors";
import {
  generatePassword,
  SignInResponse,
  SignInStatus,
  SignUpResponse,
  SignUpStatus,
} from "../routes/cognito-magic-link-sign-up";

export interface CognitoAuthInput {
  email: string;
  name: string;
  clientMetadata: StringMap;
}

const MAX_ATTEMPTS = 3;

function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}

function retryJitter(attempt: number) {
  const BASE = 100;
  const MAX_DELAY = 10000;
  const exponential = Math.pow(2, attempt) * BASE;
  const delay = Math.min(exponential, MAX_DELAY);
  return Math.floor(Math.random() * delay);
}

async function retryRequest<T>(f: () => Promise<T>): Promise<T> {
  for (let i = 0; i < MAX_ATTEMPTS; i++) {
    try {
      return f();
    } catch (err) {
      if (i === MAX_ATTEMPTS - 1) {
        throw err;
      }

      const delay = retryJitter(i);
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  throw new Error("Did not expect to reach this place");
}

function signInRetry(
  ...args: Parameters<typeof signIn>
): ReturnType<typeof signIn> {
  return retryRequest(() => signIn(...args));
}

function signUpRetry(
  ...args: Parameters<typeof signUp>
): ReturnType<typeof signUp> {
  return retryRequest(() => signUp(...args));
}

function confirmSignInRetry(
  ...args: Parameters<typeof confirmSignIn>
): ReturnType<typeof confirmSignIn> {
  return retryRequest(() => confirmSignIn(...args));
}

export const signUserIn = async ({
  email,
  name,
  clientMetadata,
}: CognitoAuthInput): Promise<SignInResponse> => {
  try {
    const username = email;
    const { isSignedIn, nextStep } = await signInRetry({
      username,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
        userAttributes: {
          name,
          email,
        },
        autoSignIn: false,
        clientMetadata,
      },
    });

    if (isSignedIn) {
      return {
        status: SignInStatus.UserAlreadySignedIn,
        error: new Error(
          `Sign in step returned already signed in: ${isSignedIn}`,
        ),
      };
    }

    if (nextStep.signInStep !== "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
      return {
        status: SignInStatus.UserChallangeInvalid,
        error: new Error(
          `Sign in step returned invalid sign in step: ${nextStep.signInStep}`,
        ),
      };
    }

    const { isSignedIn: confirmSignedIn, nextStep: confirmNextStep } =
      await confirmSignInRetry({
        challengeResponse: "__dummy__",
        options: { clientMetadata },
      });

    if (confirmSignedIn) {
      return {
        status: SignInStatus.UserConfirmedSignedIn,
        error: new Error(
          `Confirmation step returned already signed in: ${confirmSignedIn}`,
        ),
      };
    }

    if (
      confirmNextStep.signInStep !== "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
    ) {
      return {
        status: SignInStatus.UserConfirmedChallangeInvalid,
        error: new Error(
          `Confirmation step returned invalid sign in step: ${confirmNextStep.signInStep}`,
        ),
      };
    }

    return { status: SignInStatus.MagicLinkSent };
  } catch (err) {
    if (isCognitoError(err)) {
      if (err.name === "UserNotFoundException") {
        return { status: SignInStatus.UserNotFound, error: err };
      }

      return {
        status: SignInStatus.UnknownError,
        error: new Error(`Got unexpected cognito error: ${err.message}`),
      };
    }

    return {
      status: SignInStatus.UnknownError,
      error: new Error(
        `Got unexpected error from sign in step: ${getErrorMessage(err)}`,
      ),
    };
  }
};

export const signUserUp = async ({
  email,
  name,
  clientMetadata,
}: CognitoAuthInput): Promise<SignUpResponse> => {
  const password = generatePassword();

  try {
    const { isSignUpComplete, nextStep, userId } = await signUpRetry({
      username: email,
      password,
      options: {
        userAttributes: {
          name,
          email,
        },
        autoSignIn: false,
        clientMetadata,
      },
    });

    if (!isSignUpComplete || nextStep.signUpStep !== "DONE") {
      return {
        status: SignUpStatus.SignUpNotDone,
        error: new Error(
          "Unexpected state (" +
            isSignUpComplete +
            ", " +
            nextStep.signUpStep +
            ") after sign up",
        ),
      };
    }

    if (!userId) {
      return {
        status: SignUpStatus.SignUpNotDone,
        error: new Error("User ID is missing after sign up"),
      };
    }

    const signInStatus = await signUserIn({
      email,
      name,
      clientMetadata,
    });

    if (signInStatus.status === SignInStatus.MagicLinkSent) {
      return { status: SignUpStatus.MagicLinkSent };
    }

    return {
      status: SignUpStatus.UnknownError,
      error: new Error(`Error from sign in: ${signInStatus.error}`),
    };
  } catch (err) {
    if (isCognitoError(err)) {
      return {
        status: SignUpStatus.UnknownError,
        error: new Error(`Got unexpected cognito error: ${err.message}`),
      };
    }

    return {
      status: SignUpStatus.UnknownError,
      error: new Error(
        `Got unexpected error from sign up step: ${getErrorMessage(err)}`,
      ),
    };
  }
};
