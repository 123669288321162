"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultCards = void 0;
exports.defaultCards = [
    {
        description: "dataGuards.cardGuard.description.welcomeToMamaHealth",
        image: "dataGuards.cardGuard.image.hi",
    },
    {
        description: "dataGuards.cardGuard.description.ourMission",
        image: "dataGuards.cardGuard.image.light-bulb",
    },
    {
        description: "dataGuards.cardGuard.description.weGatherPatientHistories",
        image: "dataGuards.cardGuard.image.chat-bubble",
    },
    {
        description: "dataGuards.cardGuard.description.toProvideYouWithAnswers",
        image: "dataGuards.cardGuard.image.statistics",
    },
    {
        description: "dataGuards.cardGuard.description.diseaseProgression",
        image: "dataGuards.cardGuard.image.medical-note",
    },
    {
        description: "dataGuards.cardGuard.description.andCompareYourProgress",
        image: "dataGuards.cardGuard.image.bar-chart",
    },
    {
        description: "dataGuards.cardGuard.description.happyChatting",
        image: "dataGuards.cardGuard.image.face",
    },
];
