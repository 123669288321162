/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiseaseSettings } from './DiseaseSettings';
import {
    DiseaseSettingsFromJSON,
    DiseaseSettingsFromJSONTyped,
    DiseaseSettingsToJSON,
} from './DiseaseSettings';
import type { LandbotSettings } from './LandbotSettings';
import {
    LandbotSettingsFromJSON,
    LandbotSettingsFromJSONTyped,
    LandbotSettingsToJSON,
} from './LandbotSettings';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a UserSettings record
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    landbotUrlTx?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    landbotLanguage?: string;
    /**
     * 
     * @type {Array<LandbotSettings>}
     * @memberof UserSettings
     */
    landbotSettings?: Array<LandbotSettings>;
    /**
     * 
     * @type {User}
     * @memberof UserSettings
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    userId: string;
    /**
     * 
     * @type {DiseaseSettings}
     * @memberof UserSettings
     */
    disease?: DiseaseSettings;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    diseaseId: string;
}

/**
 * Check if a given object implements the UserSettings interface.
 */
export function instanceOfUserSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "diseaseId" in value;

    return isInstance;
}

export function UserSettingsFromJSON(json: any): UserSettings {
    return UserSettingsFromJSONTyped(json, false);
}

export function UserSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'landbotUrlTx': !exists(json, 'landbotUrlTx') ? undefined : json['landbotUrlTx'],
        'landbotLanguage': !exists(json, 'landbotLanguage') ? undefined : json['landbotLanguage'],
        'landbotSettings': !exists(json, 'landbotSettings') ? undefined : ((json['landbotSettings'] as Array<any>).map(LandbotSettingsFromJSON)),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': json['userId'],
        'disease': !exists(json, 'disease') ? undefined : DiseaseSettingsFromJSON(json['disease']),
        'diseaseId': json['diseaseId'],
    };
}

export function UserSettingsToJSON(value?: UserSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'landbotUrlTx': value.landbotUrlTx,
        'landbotLanguage': value.landbotLanguage,
        'landbotSettings': value.landbotSettings === undefined ? undefined : ((value.landbotSettings as Array<any>).map(LandbotSettingsToJSON)),
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'disease': DiseaseSettingsToJSON(value.disease),
        'diseaseId': value.diseaseId,
    };
}

