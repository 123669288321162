import { confirmSignUp } from "aws-amplify/auth";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  CognitoClientMetadata,
  validateCognitoClientMetadata,
} from "shared/types/cognito-client-metadata";

import { cognitoPaths } from "./paths";
import { LoadingSpinner } from "../../../../components/loading-spinner";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { AuthMessageLevel } from "../../generic/types";
import { useAuthNavigate } from "../../generic/use-auth-navigate";
import { useCognitoConfiguration } from "../hooks/use-cognito-configuration";
import { CognitoError, handleCognitoError } from "../misc/cognito-errors";

export const CognitoConfirmSignUpCallback: React.FC = () => {
  const cognitoConfigurationState = useCognitoConfiguration();

  const [params] = useSearchParams();
  const { sub, confirmation_code, email, lng, ...otherAttributes } = useMemo(
    () => Object.fromEntries(params.entries()),
    [params],
  );

  const navigate = useAuthNavigate(email ? () => ({ email }) : undefined);

  const hasSentRequest = useRef(false);
  const { disease, organisation } = useTenantId();
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    try {
      const clientMetadata = validateCognitoClientMetadata({
        disease,
        ...(organisation ? { organisation } : {}),
        isLocalhost: process.env.NODE_ENV === "development" ? "1" : "0",
        language,
        ...otherAttributes,
      }) as CognitoClientMetadata & {
        [key: string]: string;
      };

      if (sub && confirmation_code) {
        if (cognitoConfigurationState === "ready" && !hasSentRequest.current) {
          hasSentRequest.current = true;
          confirmSignUp({
            username: sub,
            confirmationCode: confirmation_code,
            options: { clientMetadata },
          })
            .then(() => {
              navigate({
                to: { type: "uri", uri: cognitoPaths.signIn },
                replace: true,
                state: {
                  message: {
                    level: AuthMessageLevel.INFO,
                    tx: "auth.signUp.registrationConfirmed",
                  },
                },
              });
            })
            .catch(async (err: CognitoError) => {
              navigate({
                to: { type: "uri", uri: cognitoPaths.signIn },
                replace: true,
                state: {
                  message: {
                    level: AuthMessageLevel.ERROR,
                    ...handleCognitoError(err),
                  },
                },
              });
            });
        }
      }
      return;
    } catch {
      /* empty */
    }
    navigate({
      to: { type: "uri", uri: cognitoPaths.signIn },
      replace: true,
      state: {
        message: {
          level: AuthMessageLevel.ERROR,
          tx: "auth.signUp.confirmationLinkError",
        },
      },
    });
  }, [
    cognitoConfigurationState,
    confirmation_code,
    otherAttributes,
    disease,
    language,
    navigate,
    organisation,
    sub,
  ]);

  return (
    <div className="min-h-16 flex flex-row items-center justify-center self-stretch">
      <LoadingSpinner
        message={{ tx: "auth.signUp.confirmingSignUpWaitingMessage" }}
      />
    </div>
  );
};
