/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GroupInfoDto
 */
export interface GroupInfoDto {
    /**
     * 
     * @type {string}
     * @memberof GroupInfoDto
     */
    groupSecret?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupInfoDto
     */
    group: GroupInfoDtoGroupEnum;
    /**
     * 
     * @type {string}
     * @memberof GroupInfoDto
     */
    organisation?: GroupInfoDtoOrganisationEnum;
}


/**
 * @export
 */
export const GroupInfoDtoGroupEnum = {
    User: 'USER',
    Admin: 'ADMIN',
    Member: 'MEMBER',
    Pag: 'PAG',
    Pharma: 'PHARMA',
    PharmaDemo: 'PHARMA_DEMO'
} as const;
export type GroupInfoDtoGroupEnum = typeof GroupInfoDtoGroupEnum[keyof typeof GroupInfoDtoGroupEnum];

/**
 * @export
 */
export const GroupInfoDtoOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type GroupInfoDtoOrganisationEnum = typeof GroupInfoDtoOrganisationEnum[keyof typeof GroupInfoDtoOrganisationEnum];


/**
 * Check if a given object implements the GroupInfoDto interface.
 */
export function instanceOfGroupInfoDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "group" in value;

    return isInstance;
}

export function GroupInfoDtoFromJSON(json: any): GroupInfoDto {
    return GroupInfoDtoFromJSONTyped(json, false);
}

export function GroupInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupSecret': !exists(json, 'groupSecret') ? undefined : json['groupSecret'],
        'group': json['group'],
        'organisation': !exists(json, 'organisation') ? undefined : json['organisation'],
    };
}

export function GroupInfoDtoToJSON(value?: GroupInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupSecret': value.groupSecret,
        'group': value.group,
        'organisation': value.organisation,
    };
}

