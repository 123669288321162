import { Navigate, RouteObject } from "react-router-dom";
import { TenantIdentifier } from "shared/config";
import { basePathOfTenant } from "shared/config/base-urls";

import { CognitoConfirmSignIn } from "./cognito-confirm-sign-in";
import { CognitoConfirmSignUp } from "./cognito-confirm-sign-up";
import { CognitoConfirmSignUpCallback } from "./cognito-confirm-sign-up-callback";
import { CognitoLayout } from "./cognito-layout";
import { CognitoLoginProblem } from "./cognito-login-problem";
import { CognitoMagicLinkSignIn } from "./cognito-magic-link-sign-in";
import { CognitoMagicLinkSignUp } from "./cognito-magic-link-sign-up";
import { CognitoMigrateAccount } from "./cognito-migrate-account/cognito-migrate-account";
import { CognitoNeedToMigrateAccountNotice } from "./cognito-migrate-account/cognito-need-to-migrate-account-notice";
import { CognitoResetPassword } from "./cognito-reset-password";
import { CognitoResetPasswordFromCode } from "./cognito-reset-password-from-code";
import { CognitoSessionCleanup } from "./cognito-session-cleanup";
import { CognitoSignIn } from "./cognito-sign-in";
import { CognitoSignUp } from "./cognito-sign-up";
import { CognitoSignUpDeprecated } from "./cognito-sign-up-deprecated";
import { CognitoSocialLogoutHandler } from "./cognito-social-logout-handler";
import { CognitoSocialRedirectHandler } from "./cognito-social-redirect-handler";
import { CognitoUpdateEmailCallback } from "./cognito-update-email-callback";
import { CognitoUpdateEmailInfo } from "./cognito-update-email-info";
import { CognitoVerifyMagicLink } from "./cognito-verify-magic-link";
import { cognitoPaths } from "./paths";
import { PublicChat } from "../../../../components/chat/ai/ask/ai-public-chat";
import { RouteWithTitle } from "../../../../util/metadata-for-active-route";

export const routesWithoutAutoSignInForward = [
  cognitoPaths.confirmSignUpCallback,
  cognitoPaths.updateEmailCallback,
  cognitoPaths.updateEmailInfo,
  cognitoPaths.sessionCleanup,
  cognitoPaths.socialLogoutHandler,
];

export const cognitoAuthRoutesWithReducedLayout: RouteWithTitle[] = [
  {
    path: cognitoPaths.onboarding,
    element: <PublicChat />,
    title: {
      tx: "chat.headerTitle",
    },
  },
];

const cognitoAuthRoutesWithLayout: RouteWithTitle[] = [
  {
    path: cognitoPaths.signIn,
    element: <CognitoSignIn />,
    title: {
      tx: "auth.signIn.title",
    },
  },
  {
    path: cognitoPaths.magicSignIn,
    element: <CognitoMagicLinkSignIn />,
    title: {
      tx: "auth.signIn.title",
    },
  },
  {
    path: cognitoPaths.signUp,
    element: <CognitoSignUp />,
    title: {
      tx: "auth.signUp.title",
    },
  },
  {
    path: cognitoPaths.signUpDeprecated,
    element: <CognitoSignUpDeprecated />,
    title: {
      tx: "auth.signUp.titleDeprecated",
    },
  },
  {
    path: cognitoPaths.magicSignUp,
    element: <CognitoMagicLinkSignUp />,
    title: {
      tx: "auth.signInMagic.title",
    },
  },
  {
    path: cognitoPaths.confirmSignInInfo,
    element: <CognitoConfirmSignIn />,
    title: {
      tx: "auth.signInMagic.title",
    },
  },
  {
    path: cognitoPaths.confirmSignUpInfo,
    element: <CognitoConfirmSignUp />,
    title: {
      tx: "auth.signUp.title",
    },
  },
  {
    path: cognitoPaths.confirmSignUpCallback,
    element: <CognitoConfirmSignUpCallback />,
    title: {
      tx: "auth.signUp.title",
    },
  },
  {
    path: cognitoPaths.updateEmailCallback,
    element: <CognitoUpdateEmailCallback />,
    title: {
      tx: "profile.confirmEmailUpdateTitle",
    },
  },
  {
    path: cognitoPaths.loginProblem,
    element: <CognitoLoginProblem />,
    title: {
      tx: "auth.loginProblem.title",
    },
  },
  {
    path: cognitoPaths.updateEmailInfo,
    element: <CognitoUpdateEmailInfo />,
    title: {
      tx: "profile.confirmEmailUpdateTitle",
    },
  },
  {
    path: cognitoPaths.resetPassword,
    element: <CognitoResetPassword />,
    title: {
      tx: "auth.resetPassword.title",
    },
  },
  {
    path: cognitoPaths.confirmResetPassword,
    element: <CognitoResetPasswordFromCode />,
    title: {
      tx: "auth.resetPassword.title",
    },
  },
  {
    path: cognitoPaths.needToMigrateAccountNotice,
    element: <CognitoNeedToMigrateAccountNotice />,
    title: {
      tx: "auth.migrate.needToMigrateAccountNoticeTitle",
    },
  },
  {
    path: cognitoPaths.migrateAccount,
    element: <CognitoMigrateAccount />,
    title: {
      tx: "auth.migrate.migrateAccountTitle",
    },
  },
  {
    path: cognitoPaths.verifyMagicLink,
    element: <CognitoVerifyMagicLink />,
    title: {
      tx: "auth.migrate.migrateAccountTitle",
    },
  },
];

export const getCognitoAuthRoutes = (
  activeTenant: TenantIdentifier,
): RouteObject[] => {
  const basePath = basePathOfTenant(activeTenant);
  const routesForTenant = cognitoAuthRoutesWithLayout.map((route) => ({
    ...route,
    path: basePath + route.path,
  }));
  const chat = cognitoAuthRoutesWithReducedLayout.find(
    (route) => route.path === cognitoPaths.onboarding,
  );

  if (!chat) {
    throw new Error("onboarding public chat route not found");
  }

  return [
    {
      path: basePath + cognitoPaths.socialRedirectHandler,
      element: <CognitoSocialRedirectHandler />,
    },
    {
      path: basePath + cognitoPaths.socialLogoutHandler,
      element: <CognitoSocialLogoutHandler />,
    },
    {
      path: basePath + cognitoPaths.sessionCleanup,
      element: <CognitoSessionCleanup />,
    },
    {
      path: basePath + chat.path,
      element: chat.element,
    },
    {
      path: basePath + "/auth",
      element: <CognitoLayout routes={routesForTenant} />,
      children: [
        ...routesForTenant.map((route) => ({
          path: route.path,
          element: route.element,
        })),
        {
          path: basePath + "/auth/*",
          element: <Navigate to={basePath} replace={true} />,
        },
        {
          path: basePath + "/auth/",
          index: true,
          element: <Navigate to={basePath} replace={true} />,
        },
      ],
    },
  ];
};
