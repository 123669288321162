import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Disease } from "shared/model/diseases";

import { StartConversationRequest } from "../../../../api/generated/multiagent";
import { useApiMutation } from "../../../../api/use-api";
import { CONVERSATIONS_KEY } from "../../../../types/query-keys";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { useUser } from "../../../../util/use-user";
import { LoadingScreen } from "../../../loading-screen";

export const AiChatNewConversation: React.FC = () => {
  const user = useUser();
  const {
    i18n: { language },
  } = useTranslation();

  const queryClient = useQueryClient();
  const { disease } = useTenantId();

  const { mutate: createConversation } = useApiMutation(
    "multiagent",
    (api) => (request: StartConversationRequest) =>
      api.startConversation(request),
    undefined,
    undefined,
    {
      onSuccess: (_, { mamaDisease }) => {
        queryClient.invalidateQueries(
          CONVERSATIONS_KEY(mamaDisease as Disease),
        );
      },
    },
  );

  const hasSentRequest = useRef(false);

  useEffect(() => {
    const userName = user?.userAttributes?.name;
    if (!hasSentRequest.current && userName) {
      hasSentRequest.current = true;
      createConversation({
        mamaDisease: disease,
        mamaLanguage: language,
      });
    }
  }, [disease, language, createConversation, user]);

  return <LoadingScreen message={{ tx: "chat.loadingInitialChat" }} />;
};
