import { useState } from "react";

import { AutoResizingTextInput } from "../../../../../form/auto-resizing-text-input";
import { Button } from "../../../../../form/button";
import { ChatOptionComponentProps } from "../lookup";

export const TextDrawerSimple: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const [message, setMessage] = useState("");

  const sendMessage = () => {
    sendResponse({ message, selection: [] });
    setMessage("");
  };

  return (
    <div className="flex w-full flex-row gap-4">
      <AutoResizingTextInput
        className="resize-none pr-12 leading-snug"
        as="textarea"
        value={message}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !event.shiftKey) {
            sendMessage();
            event.preventDefault();
            event.stopPropagation();
          }
        }}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      />
      <Button
        isDisabled={message.length === 0}
        className="self-end"
        icon="paperPlane"
        onClick={sendMessage}
      />
    </div>
  );
};
