import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Country } from "shared/model/countries";

import { BasicWithButton } from "./fragments/basic-with-button";
import { SearchableDropdown } from "../../../../../form/searchable-dropdown";
import { ChatOptionComponentProps } from "../lookup";

export const CountryDropdown: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const { t } = useTranslation();

  const [selectedCountry, setCountry] = useState<Country | undefined>();

  return (
    <BasicWithButton
      message={selectedCountry ? t(`countries.${selectedCountry}`) : ""}
      selection={selectedCountry ? [selectedCountry] : []}
      sendResponse={sendResponse}
    >
      <SearchableDropdown
        options={Object.values(Country).map((v) => ({
          label: { tx: `countries.${v}` },
          value: v,
        }))}
        resultsLimit={10}
        searchResultsOnTop
        onChange={(country) => setCountry(country as Country)}
      />
    </BasicWithButton>
  );
};
