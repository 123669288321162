import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { organisationMap } from "shared/config";
import { basePathOfTenant } from "shared/config/base-urls";
import { SupportedGroupsEnum } from "shared/model/cognito";
import { Disease } from "shared/model/diseases";
import { Organisation } from "shared/model/organisations";
import { twMerge } from "tailwind-merge";

import { NeutralButton, PrimaryButton } from "./form/button";
import { LoadingButton } from "./form/loading-button";
import { Text } from "./text";
import {
  JoinGroupRequest,
  LeaveGroupRequest,
  UserTenantGroupsEnum,
} from "../api/generated/backend";
import { UserGroup } from "../api/generated/multiagent";
import { toTenantDisease, useApiMutation } from "../api/use-api";
import {
  FOLLOWED_PAGS_KEY,
  IS_USER_FOLLOWING_TENANT_KEY,
  ME_KEY,
} from "../types/query-keys";
import { FlagOfLanguage } from "../util/flags";

type OrganisationCardProps = {
  disease: Disease;
  organisation: Organisation;
  userGroupsInOrganisation?: UserTenantGroupsEnum[];
} & JSX.IntrinsicElements["div"];

const guideLinkMap: Record<Organisation, string> = {
  [Organisation.ENDOMETRIOSEVEREINIGUNG]:
    "https://www.endometriose-vereinigung.de/mitglied-werden/",
  [Organisation.AENDO]:
    "https://www.aendoassociazione.com/dona-ora/20-aendo/dona-ora/147-diventa-socio-di-aendo",
  [Organisation.AILC]: "",
  [Organisation.DADI]: "",
  [Organisation.DERMATITEATOPICADIARY]: "",
  [Organisation.ENDO_CARE]: "https://www.endo-care.it/contattaci/",
  [Organisation.NOI_CON_VOI]:
    "https://www.endometriosialtoadigenoiconvoi.it/page/iscriviti",
  [Organisation.TANALENTAMENTE]: "",
  [Organisation.MG_ITALY]: "",
};

export const OrganisationCard: React.FC<OrganisationCardProps> = ({
  className,
  disease,
  organisation,
  userGroupsInOrganisation,
  ...rest
}) => {
  const queryClient = useQueryClient();

  const tenantId = useMemo(
    () => ({ disease, organisation }),
    [disease, organisation],
  );
  const organisationConfig = organisationMap[organisation];
  const userFollowsOrganisation = useMemo(
    () => userGroupsInOrganisation?.includes(UserTenantGroupsEnum.User),
    [userGroupsInOrganisation],
  );
  const userIsMemberOfOrganisation = useMemo(
    () => userGroupsInOrganisation?.includes(UserTenantGroupsEnum.Member),
    [userGroupsInOrganisation],
  );
  const navigate = useNavigate();

  const { mutate: joinGroup, isLoading: isJoiningGroup } = useApiMutation(
    "backend",
    (api) => (request: JoinGroupRequest) => api.joinGroup(request),
    undefined,
    undefined,
    {
      onSuccess: async (_, header) => {
        queryClient.invalidateQueries(ME_KEY(toTenantDisease(header)));
        queryClient.invalidateQueries(IS_USER_FOLLOWING_TENANT_KEY(tenantId));
        queryClient.invalidateQueries(FOLLOWED_PAGS_KEY(disease));
      },
    },
  );

  const { mutate: leaveGroup, isLoading: isLeavingGroup } = useApiMutation(
    "backend",
    (api) => (request: LeaveGroupRequest) => api.leaveGroup(request),
    undefined,
    undefined,
    {
      onSuccess: (_, header) => {
        queryClient.invalidateQueries(ME_KEY(toTenantDisease(header)));
        queryClient.invalidateQueries(IS_USER_FOLLOWING_TENANT_KEY(tenantId));
        queryClient.invalidateQueries(FOLLOWED_PAGS_KEY(disease));
      },
    },
  );

  const guideLink = useMemo(() => guideLinkMap[organisation], [organisation]);

  return (
    <div className={twMerge("card bg-base-100 shadow-xl", className)} {...rest}>
      <figure className="px-8 pt-8">
        <img
          src={organisationConfig.authLogo}
          className="h-12 w-full min-w-fit object-contain object-left"
        />
      </figure>

      <div
        className={twMerge(
          "card-body",
          userFollowsOrganisation ? "justify-center" : "",
        )}
      >
        {
          <>
            <div className=" w-full">
              <Text
                className="card-title float-left"
                as="h3"
                tx={organisationConfig.tenantNameTx}
              />
              <div className="float-left">
                {organisationConfig.pagLanguages.map((language) => (
                  <div className="badge badge-neutral ml-2" key={language}>
                    <Text
                      as="i"
                      tx={`i18n.languages.${language}`}
                      className="mr-1"
                    />
                    <FlagOfLanguage language={language} className="h-3" />
                  </div>
                ))}
              </div>
            </div>

            <Text as="p" tx={organisationConfig.metaDescriptionTx} />
            <div className="card-actions justify-end">
              <LoadingButton
                Button={userFollowsOrganisation ? NeutralButton : PrimaryButton}
                onClick={() =>
                  userFollowsOrganisation
                    ? leaveGroup({
                        groupInfoDto: {
                          organisation,
                          group: UserGroup.User,
                        },
                        mamaDisease: disease,
                        mamaOrganisation: organisation,
                      })
                    : joinGroup(
                        {
                          groupInfoDto: {
                            organisation,
                            group: UserGroup.User,
                          },
                          mamaDisease: disease,
                          mamaOrganisation: organisation,
                        },
                        {
                          onSuccess: () => {
                            navigate(basePathOfTenant(tenantId));
                          },
                        },
                      )
                }
                loading={isJoiningGroup || isLeavingGroup}
                tx={
                  userFollowsOrganisation
                    ? "pagList.unfollowButton"
                    : "organisation-disease-guard.followButton"
                }
                className="self-start"
              />
              <LoadingButton
                Button={
                  userIsMemberOfOrganisation ? NeutralButton : PrimaryButton
                }
                loading={isJoiningGroup || isLeavingGroup}
                tx={
                  userIsMemberOfOrganisation
                    ? "userData.memberOfAssociation.noLongerMember"
                    : "userData.memberOfAssociation.becomeAMember"
                }
                onClick={() => {
                  !userIsMemberOfOrganisation
                    ? joinGroup({
                        groupInfoDto: {
                          organisation,
                          group: SupportedGroupsEnum.MEMBER,
                        },
                        mamaDisease: disease,
                        mamaOrganisation: organisation,
                      })
                    : leaveGroup({
                        groupInfoDto: {
                          organisation,
                          group: SupportedGroupsEnum.MEMBER,
                        },
                        mamaDisease: disease,
                        mamaOrganisation: organisation,
                      });
                }}
              />
            </div>

            {!userIsMemberOfOrganisation && guideLink && (
              <Text
                className="cursor-pointer self-end italic underline"
                tx="userData.memberOfAssociation.howToBecomeAMember"
                onClick={() => window.open(guideLink, "_blank")}
              />
            )}
          </>
        }
      </div>
    </div>
  );
};
