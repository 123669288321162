/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrganisationGroups } from './OrganisationGroups';
import {
    OrganisationGroupsFromJSON,
    OrganisationGroupsFromJSONTyped,
    OrganisationGroupsToJSON,
} from './OrganisationGroups';
import type { Settings } from './Settings';
import {
    SettingsFromJSON,
    SettingsFromJSONTyped,
    SettingsToJSON,
} from './Settings';
import type { UserAttributes } from './UserAttributes';
import {
    UserAttributesFromJSON,
    UserAttributesFromJSONTyped,
    UserAttributesToJSON,
} from './UserAttributes';
import type { UserDetails } from './UserDetails';
import {
    UserDetailsFromJSON,
    UserDetailsFromJSONTyped,
    UserDetailsToJSON,
} from './UserDetails';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {Settings}
     * @memberof User
     */
    settings?: Settings;
    /**
     * 
     * @type {UserAttributes}
     * @memberof User
     */
    userAttributes?: UserAttributes;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    tenantGroups: Array<UserTenantGroupsEnum>;
    /**
     * 
     * @type {Array<OrganisationGroups>}
     * @memberof User
     */
    organisationMemberGroups: Array<OrganisationGroups>;
    /**
     * 
     * @type {UserDetails}
     * @memberof User
     */
    userDetails?: UserDetails;
}


/**
 * @export
 */
export const UserTenantGroupsEnum = {
    User: 'USER',
    Admin: 'ADMIN',
    Member: 'MEMBER',
    Pag: 'PAG',
    Pharma: 'PHARMA',
    PharmaDemo: 'PHARMA_DEMO'
} as const;
export type UserTenantGroupsEnum = typeof UserTenantGroupsEnum[keyof typeof UserTenantGroupsEnum];


/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "tenantGroups" in value;
    isInstance = isInstance && "organisationMemberGroups" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'settings': !exists(json, 'settings') ? undefined : SettingsFromJSON(json['settings']),
        'userAttributes': !exists(json, 'userAttributes') ? undefined : UserAttributesFromJSON(json['userAttributes']),
        'tenantGroups': json['tenantGroups'],
        'organisationMemberGroups': ((json['organisationMemberGroups'] as Array<any>).map(OrganisationGroupsFromJSON)),
        'userDetails': !exists(json, 'userDetails') ? undefined : UserDetailsFromJSON(json['userDetails']),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'settings': SettingsToJSON(value.settings),
        'userAttributes': UserAttributesToJSON(value.userAttributes),
        'tenantGroups': value.tenantGroups,
        'organisationMemberGroups': ((value.organisationMemberGroups as Array<any>).map(OrganisationGroupsToJSON)),
        'userDetails': UserDetailsToJSON(value.userDetails),
    };
}

