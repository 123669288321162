import React from "react";

import { ChoicesWithText } from "./fragments/choices-with-text";
import { Link } from "../../../../../link";
import { Text } from "../../../../../text";
import { ChatOptionComponentProps } from "../lookup";

export const Terms: React.FC<ChatOptionComponentProps> = (props) => {
  return (
    <ChoicesWithText {...props}>
      <Text
        className="label-text font-bold"
        tx="signUp.customFields.mamaTermsAndConditions.agreeToTermsAndConditions"
        txComponents={{
          href: <Link className="underline" target="_blank" />,
        }}
      />
      <Text
        className="label-text font-bold"
        tx="signUp.customFields.mamaPrivacyPolicy.checkOutPrivacyPolicy"
        txComponents={{
          href: <Link target="_blank" className="underline" />,
        }}
      />
    </ChoicesWithText>
  );
};
