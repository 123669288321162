import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { pathSegmentOfEnum } from "shared/config/base-urls";
import { FrontendPageEnum, IFramePage } from "shared/model/pages";

import { frontendPages } from "./frontend-pages";
import { isIFramePage } from "./is-page-type";
import { BareTenantPage, TenantPage } from "./use-tenant-pages";
import { useT } from "../i18n/use-t";
import { subdomainOfAppEnv } from "../util/env-utils";

type PageInfo<P extends TenantPage> = (P["page"] extends IFramePage
  ? { iFrameUrl: string }
  : // eslint-disable-next-line @typescript-eslint/ban-types
    {}) & {
  iFrameUrl?: string;
  title: string;
  groupedByTitle?: string;
};

export const useGetPageInfo = (): (<P extends BareTenantPage>(
  page: P,
) => PageInfo<TenantPage>) => {
  const t = useT();
  const {
    i18n: { exists },
  } = useTranslation();
  const translateOptional = useCallback(
    (tx: string) =>
      exists(tx)
        ? t({
            txUnchecked: tx,
            txData: { subdomainOfAppEnv: subdomainOfAppEnv },
          })
        : undefined,
    [exists, t],
  );

  return useCallback(
    <P extends BareTenantPage>(page: P) => {
      const title =
        translateOptional(titleTx(page)) ??
        (page.page.route in frontendPages
          ? t(frontendPages[page.page.route as FrontendPageEnum].title)
          : undefined);
      if (!title) {
        const error = new Error(
          `Page ${page.page.txPageName} at ${
            page.page.route
          } did not have a title (searched at ${titleTx(page)}).`,
        );
        console.error(error);
        throw error;
      }

      const iFrameUrl = translateOptional(iFrameUrlTx(page));
      if (isIFramePage(page.page) && !iFrameUrl) {
        const error = new Error(
          `IFrame Page ${page.page.txPageName} at ${
            page.page.route
          } did not have a url (searched at ${iFrameUrlTx(page)}).`,
        );
        console.error(error);
        throw error;
      }

      return {
        iFrameUrl,
        title,
        groupedByTitle: translateOptional(groupedByTitleTx(page)),
      } as PageInfo<TenantPage>;
    },
    [t, translateOptional],
  );
};

const iFrameUrlTx = (page: BareTenantPage): string => {
  return pageKey(page, "url");
};

const titleTx = (page: BareTenantPage): string => {
  return pageKey(page, "title");
};

const groupedByTitleTx = (page: BareTenantPage): string => {
  return pageKey(page, "groupedByTitle");
};

const pageKey = (
  { page: { txPageName }, tenantId: { disease, organisation } }: BareTenantPage,
  property: string,
): string => {
  if (organisation) {
    return `organisation.${organisation}.${txPageName}.${property}`;
  } else {
    return `disease.${pathSegmentOfEnum(disease)}.${txPageName}.${property}`;
  }
};
