/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePublicConversationUserAttributes
 */
export interface CreatePublicConversationUserAttributes {
    /**
     * 
     * @type {string}
     * @memberof CreatePublicConversationUserAttributes
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicConversationUserAttributes
     */
    detail?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicConversationUserAttributes
     */
    utmSource?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicConversationUserAttributes
     */
    utmCampaign?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicConversationUserAttributes
     */
    utmContent?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicConversationUserAttributes
     */
    utmMedium?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicConversationUserAttributes
     */
    utmTerm?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicConversationUserAttributes
     */
    placement?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicConversationUserAttributes
     */
    matchtype?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicConversationUserAttributes
     */
    campaignId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicConversationUserAttributes
     */
    adgroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicConversationUserAttributes
     */
    adId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicConversationUserAttributes
     */
    fbclid?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePublicConversationUserAttributes
     */
    gclid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePublicConversationUserAttributes
     */
    isTestUser?: boolean;
}

/**
 * Check if a given object implements the CreatePublicConversationUserAttributes interface.
 */
export function instanceOfCreatePublicConversationUserAttributes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreatePublicConversationUserAttributesFromJSON(json: any): CreatePublicConversationUserAttributes {
    return CreatePublicConversationUserAttributesFromJSONTyped(json, false);
}

export function CreatePublicConversationUserAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePublicConversationUserAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': !exists(json, 'source') ? undefined : json['source'],
        'detail': !exists(json, 'detail') ? undefined : json['detail'],
        'utmSource': !exists(json, 'utm_source') ? undefined : json['utm_source'],
        'utmCampaign': !exists(json, 'utm_campaign') ? undefined : json['utm_campaign'],
        'utmContent': !exists(json, 'utm_content') ? undefined : json['utm_content'],
        'utmMedium': !exists(json, 'utm_medium') ? undefined : json['utm_medium'],
        'utmTerm': !exists(json, 'utm_term') ? undefined : json['utm_term'],
        'placement': !exists(json, 'placement') ? undefined : json['placement'],
        'matchtype': !exists(json, 'matchtype') ? undefined : json['matchtype'],
        'campaignId': !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        'adgroupId': !exists(json, 'adgroup_id') ? undefined : json['adgroup_id'],
        'adId': !exists(json, 'ad_id') ? undefined : json['ad_id'],
        'fbclid': !exists(json, 'fbclid') ? undefined : json['fbclid'],
        'gclid': !exists(json, 'gclid') ? undefined : json['gclid'],
        'isTestUser': !exists(json, 'is_test_user') ? undefined : json['is_test_user'],
    };
}

export function CreatePublicConversationUserAttributesToJSON(value?: CreatePublicConversationUserAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': value.source,
        'detail': value.detail,
        'utm_source': value.utmSource,
        'utm_campaign': value.utmCampaign,
        'utm_content': value.utmContent,
        'utm_medium': value.utmMedium,
        'utm_term': value.utmTerm,
        'placement': value.placement,
        'matchtype': value.matchtype,
        'campaign_id': value.campaignId,
        'adgroup_id': value.adgroupId,
        'ad_id': value.adId,
        'fbclid': value.fbclid,
        'gclid': value.gclid,
        'is_test_user': value.isTestUser,
    };
}

