/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiseaseSettings } from './DiseaseSettings';
import {
    DiseaseSettingsFromJSON,
    DiseaseSettingsFromJSONTyped,
    DiseaseSettingsToJSON,
} from './DiseaseSettings';
import type { GroupsOfUser } from './GroupsOfUser';
import {
    GroupsOfUserFromJSON,
    GroupsOfUserFromJSONTyped,
    GroupsOfUserToJSON,
} from './GroupsOfUser';
import type { NewsStory } from './NewsStory';
import {
    NewsStoryFromJSON,
    NewsStoryFromJSONTyped,
    NewsStoryToJSON,
} from './NewsStory';
import type { SocialMediaPost } from './SocialMediaPost';
import {
    SocialMediaPostFromJSON,
    SocialMediaPostFromJSONTyped,
    SocialMediaPostToJSON,
} from './SocialMediaPost';
import type { SocialMediaPostCategory } from './SocialMediaPostCategory';
import {
    SocialMediaPostCategoryFromJSON,
    SocialMediaPostCategoryFromJSONTyped,
    SocialMediaPostCategoryToJSON,
} from './SocialMediaPostCategory';
import type { StripeInfo } from './StripeInfo';
import {
    StripeInfoFromJSON,
    StripeInfoFromJSONTyped,
    StripeInfoToJSON,
} from './StripeInfo';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a OrganisationSettings record
 * @export
 * @interface OrganisationSettings
 */
export interface OrganisationSettings {
    /**
     * 
     * @type {string}
     * @memberof OrganisationSettings
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationSettings
     */
    name: string;
    /**
     * 
     * @type {Array<StripeInfo>}
     * @memberof OrganisationSettings
     */
    stripeInfo?: Array<StripeInfo>;
    /**
     * 
     * @type {string}
     * @memberof OrganisationSettings
     */
    stripeId?: string;
    /**
     * 
     * @type {DiseaseSettings}
     * @memberof OrganisationSettings
     */
    disease?: DiseaseSettings;
    /**
     * 
     * @type {string}
     * @memberof OrganisationSettings
     */
    diseaseId: string;
    /**
     * 
     * @type {Array<NewsStory>}
     * @memberof OrganisationSettings
     */
    newsStory?: Array<NewsStory>;
    /**
     * 
     * @type {Array<GroupsOfUser>}
     * @memberof OrganisationSettings
     */
    groupsOfUser?: Array<GroupsOfUser>;
    /**
     * 
     * @type {Array<User>}
     * @memberof OrganisationSettings
     */
    usersLastActiveOnOrganisation?: Array<User>;
    /**
     * 
     * @type {Array<SocialMediaPost>}
     * @memberof OrganisationSettings
     */
    socialMediaPost?: Array<SocialMediaPost>;
    /**
     * 
     * @type {Date}
     * @memberof OrganisationSettings
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<SocialMediaPostCategory>}
     * @memberof OrganisationSettings
     */
    socialMediaPostCategory?: Array<SocialMediaPostCategory>;
}

/**
 * Check if a given object implements the OrganisationSettings interface.
 */
export function instanceOfOrganisationSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "diseaseId" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function OrganisationSettingsFromJSON(json: any): OrganisationSettings {
    return OrganisationSettingsFromJSONTyped(json, false);
}

export function OrganisationSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'stripeInfo': !exists(json, 'stripeInfo') ? undefined : ((json['stripeInfo'] as Array<any>).map(StripeInfoFromJSON)),
        'stripeId': !exists(json, 'stripeId') ? undefined : json['stripeId'],
        'disease': !exists(json, 'disease') ? undefined : DiseaseSettingsFromJSON(json['disease']),
        'diseaseId': json['diseaseId'],
        'newsStory': !exists(json, 'newsStory') ? undefined : ((json['newsStory'] as Array<any>).map(NewsStoryFromJSON)),
        'groupsOfUser': !exists(json, 'groupsOfUser') ? undefined : ((json['groupsOfUser'] as Array<any>).map(GroupsOfUserFromJSON)),
        'usersLastActiveOnOrganisation': !exists(json, 'usersLastActiveOnOrganisation') ? undefined : ((json['usersLastActiveOnOrganisation'] as Array<any>).map(UserFromJSON)),
        'socialMediaPost': !exists(json, 'socialMediaPost') ? undefined : ((json['socialMediaPost'] as Array<any>).map(SocialMediaPostFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'socialMediaPostCategory': !exists(json, 'socialMediaPostCategory') ? undefined : ((json['socialMediaPostCategory'] as Array<any>).map(SocialMediaPostCategoryFromJSON)),
    };
}

export function OrganisationSettingsToJSON(value?: OrganisationSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'stripeInfo': value.stripeInfo === undefined ? undefined : ((value.stripeInfo as Array<any>).map(StripeInfoToJSON)),
        'stripeId': value.stripeId,
        'disease': DiseaseSettingsToJSON(value.disease),
        'diseaseId': value.diseaseId,
        'newsStory': value.newsStory === undefined ? undefined : ((value.newsStory as Array<any>).map(NewsStoryToJSON)),
        'groupsOfUser': value.groupsOfUser === undefined ? undefined : ((value.groupsOfUser as Array<any>).map(GroupsOfUserToJSON)),
        'usersLastActiveOnOrganisation': value.usersLastActiveOnOrganisation === undefined ? undefined : ((value.usersLastActiveOnOrganisation as Array<any>).map(UserToJSON)),
        'socialMediaPost': value.socialMediaPost === undefined ? undefined : ((value.socialMediaPost as Array<any>).map(SocialMediaPostToJSON)),
        'createdAt': (value.createdAt.toISOString()),
        'socialMediaPostCategory': value.socialMediaPostCategory === undefined ? undefined : ((value.socialMediaPostCategory as Array<any>).map(SocialMediaPostCategoryToJSON)),
    };
}

