"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FrontendPageEnum = exports.ChatType = exports.PageLockType = void 0;
var PageLockType;
(function (PageLockType) {
    PageLockType["GROUP"] = "GROUP";
    PageLockType["PAYWALL"] = "PAYWALL";
    PageLockType["LANGUAGE"] = "LANGUAGE";
    PageLockType["CHAT_COMPLETENESS"] = "CHAT_COMPLETENESS";
})(PageLockType || (exports.PageLockType = PageLockType = {}));
var ChatType;
(function (ChatType) {
    ChatType["AI"] = "AI";
    ChatType["LANDBOT"] = "LANDBOT";
    ChatType["MIXED"] = "MIXED";
})(ChatType || (exports.ChatType = ChatType = {}));
var FrontendPageEnum;
(function (FrontendPageEnum) {
    FrontendPageEnum["MAMA_ANSWER"] = "/mama-answer";
    FrontendPageEnum["CHAT"] = "/chat";
    FrontendPageEnum["USER_STORY"] = "/user-story";
    FrontendPageEnum["JOURNEY"] = "/journey";
    FrontendPageEnum["REPORT"] = "/report";
    FrontendPageEnum["SUBSCRIPTIONS"] = "/subscriptions";
    FrontendPageEnum["NEWS"] = "/news";
    FrontendPageEnum["NEWS_DETAIL"] = "/news-detail/:id";
    FrontendPageEnum["STRIPE"] = "/admin/stripe";
    FrontendPageEnum["STRIPE_OAUTH_REDIRECT"] = "/admin/stripe/redirect";
    FrontendPageEnum["ADMIN_NEWS"] = "/admin/news";
    FrontendPageEnum["ADMIN_NEWS_EDIT"] = "/admin/news/:id";
    FrontendPageEnum["ADMIN_DASHBOARD"] = "/admin/dashboard";
    FrontendPageEnum["ADMIN_SIGNUP"] = "/admin-signup";
    FrontendPageEnum["USER_DASHBOARD"] = "/dashboard";
    FrontendPageEnum["PAG_LIST"] = "/organisations";
    FrontendPageEnum["SOCIAL_MEDIA"] = "/social-media";
    FrontendPageEnum["ADMIN_SOCIAL_MEDIA"] = "/admin/social-media";
    FrontendPageEnum["ADMIN_SOCIAL_MEDIA_EDIT"] = "/admin/social-media/:id";
    FrontendPageEnum["NOTIFICATIONS"] = "/notifications";
    FrontendPageEnum["NETWORK_BUILDER"] = "/network-builder";
})(FrontendPageEnum || (exports.FrontendPageEnum = FrontendPageEnum = {}));
