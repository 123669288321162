import { useLocation, useSearchParams } from "react-router-dom";

import { CreatePublicConversationUserAttributes } from "../api/generated/multiagent";

export const useQueryParamsForUserSignUp =
  (): CreatePublicConversationUserAttributes => {
    const { hash } = useLocation();

    const [searchParams] = useSearchParams();

    if (hash.includes("#test")) {
      return { isTestUser: true };
    }

    const getSearchParam = (key: string): string | undefined => {
      return searchParams.get(key) ?? undefined;
    };

    return {
      source: getSearchParam("source"),
      detail: getSearchParam("detail"),
      utmSource: getSearchParam("utm_source"),
      utmCampaign: getSearchParam("utm_campaign"),
      utmContent: getSearchParam("utm_content"),
      utmMedium: getSearchParam("utm_medium"),
      utmTerm: getSearchParam("utm_term"),
      placement: getSearchParam("placement"),
      matchtype: getSearchParam("matchtype"),
      campaignId: getSearchParam("campaign_id"),
      adgroupId: getSearchParam("adgroup_id"),
      adId: getSearchParam("ad_id"),
      fbclid: getSearchParam("fbclid"),
      gclid: getSearchParam("gclid"),
    };
  };
