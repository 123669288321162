import { useState } from "react";
import { TranslationFeKey } from "shared/types/translation-key";

import { Choices } from "./fragments/choices";
import { useT } from "../../../../../../i18n/use-t";
import { Button } from "../../../../../form/button";
import { Dropdown } from "../../../../../form/dropdown";
import { ChatOptionComponentProps } from "../lookup";

export const SingleChoice: React.FC<ChatOptionComponentProps> = ({
  choices,
  ...rest
}) => {
  const t = useT();
  const [option, setOption] = useState<TranslationFeKey>(choices[0]);

  return choices.length <= 3 ? (
    <Choices choices={choices} {...rest} />
  ) : (
    <div className="flex w-full items-center justify-center gap-3">
      <Dropdown
        value={option}
        searchResultsOnTop
        noValueSelected={{ tx: choices[0] }}
        options={choices.map((choice) => ({
          value: choice,
          label: { tx: choice },
        }))}
        onChange={(value) => setOption(value)}
        selectedValueClassName="whitespace-normal"
        className="w-full max-w-[340px] sm:w-max sm:max-w-full"
        {...rest}
      />
      <Button
        icon="paperPlane"
        onClick={() => {
          if (option) {
            rest.sendResponse({
              message: t({ tx: option }),
              selection: [option],
            });
          }
        }}
      />
    </div>
  );
};
