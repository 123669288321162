import React, { HTMLAttributes } from "react";
import { TenantDaisyColorTheme } from "shared/model/theme";
import { twMerge } from "tailwind-merge";

import * as icons from "./svgs";
import { ThemeColors } from "../../types/theme-extensions";

export type IconType = keyof typeof icons;

export type IconColor =
  | `fill-${keyof TenantDaisyColorTheme}`
  | `fill-${keyof ThemeColors}`;

export type SvgIconProps = {
  color?: IconColor;
  icon: IconType;
} & HTMLAttributes<SVGSVGElement> &
  React.SVGProps<SVGSVGElement>;

export const SvgIcon: React.FC<SvgIconProps> = ({
  color = "fill-base-content",
  icon,
  className,
  width = "100%",
  height = "100%",
  ...rest
}) => {
  return React.createElement(icons[icon] ?? icons.empty, {
    className: twMerge(color, className),
    width,
    height,
    ...rest,
  });
};
